<template>
  <div>
    <b-card>
      <p
        @click="generatedCard = !generatedCard"
        block
        v-b-toggle.generatedCard
        variant="light"
        class="col-12"
      >
        <b-row class="justify-content-between">
          <b-col md="auto">
            <b-button :to="{ name: 'admin-create-recurring-invoice' }">
              <feather-icon icon="FilePlusIcon" size="15" /> Nouvelle Facture
              Récurrente</b-button
            >
          </b-col>
          <b-col md="auto">
            <b-button
              :to="{ name: 'recurring-invoice-corbeille' }"
              variant="outline-danger"
            >
              <feather-icon icon="TrashIcon" size="15" />
              Corbeille
            </b-button>  
            
            <b-button
              variant="outline-primary"
              class="ml-2"
              @click="downloadRecurringInvoicesCompressedFile"
            >
              <feather-icon icon="FileIcon" size="15" />
              <b-spinner v-if="exportIsProgress" small />
              Exporter toutes les factures
            </b-button>

          </b-col>
        </b-row>
      </p>
      <hr />

      <b-card-body>
        <div class="d-flex justify-content-between flex-wrap">
          <!-- sorting  -->
          <b-form-group
            label="Filtrer par :"
            label-size="sm"
            label-align-sm="left"
            label-cols-sm="4"
            label-for="sortBySelect"
            class="mr-1 mb-md-0"
          >
            <b-input-group size="sm">
              <b-form-select
                id="sortBySelect"
                v-model="sortBy"
                :options="sortOptions"
              >
              </b-form-select>
              <b-form-select v-model="sortDesc" size="sm" :disabled="!sortBy">
                <option :value="false">Croissant</option>
                <option :value="true">Décroissant</option>
              </b-form-select>
            </b-input-group>
          </b-form-group>

          <!-- filter -->
          <b-form-group
            label-cols-sm="2"
            label-align-sm="left"
            label-size="sm"
            label-for="filterInput"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                placeholder="Recherche..."
              />
              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''">
                  Effacer
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </div>
      </b-card-body>
      <b-table
        :busy="isBusy"
        style="height: 57vh"
        empty-text="Aucune facture disponible"
        empty-filtered-text="Aucune facture trouvée"
        show-empty
        head-variant="dark"
        responsive
        :per-page="perPage"
        :current-page="currentPage"
        class="generated-card"
        sticky-header="5"
        striped
        :items="recurring_invoices"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong> Chargement...</strong>
          </div>
        </template>

        <template #cell(num)="data">
          <span
            >{{ data.item.num }}
            <b-badge
              v-if="data.item.edit_mode == 1"
              variant="warning"
              class="ml-1"
              >En cours d'édition</b-badge
            ></span
          >
        </template>

        <template #cell(periodicity)="data">
          <span v-if="data.item.every"
            >Tous les {{ data.item.every }}
            {{
              data.item.frequence == "d"
                ? "Jours"
                : data.item.frequence == "w"
                ? "Semaines"
                : data.item.frequence == "m"
                ? "Mois"
                : data.item.frequence == "y"
                ? "ans"
                : ""
            }}
          </span>
        </template>

        <template #cell(total)="data">
          <span class="p-1">{{ totalTTC(data.item) }}</span>
        </template>
        <template #cell(customer)="data">
          {{ data.item.customer.name }}
        </template>
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            class="chart-dropdown"
            toggle-class="p-0"
            right
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="18"
                class="text-body cursor-pointer"
              />
            </template>

            <!-- <b-dropdown-item
              v-if="data.item.num != null"
              :href="data.item.filename_invoice"
              target="_blank"
            >
              <feather-icon
                icon="EyeIcon"
                size="18"
                style="margin-right: 5px"
              />
              Voir la facture
            </b-dropdown-item> -->
            <b-dropdown-item
              v-if="data.item.num != null"
              :to="'/admin/edit-recurring-invoice/' + data.item.num"
            >
              <feather-icon
                icon="EditIcon"
                size="18"
                style="margin-right: 5px"
              />
              Editer la facture
            </b-dropdown-item>
            <b-dropdown-item
              :to="'/admin/recurring-invoice-files/' + data.item.num"
            >
              <feather-icon
                icon="FileIcon"
                size="18"
                style="margin-right: 5px"
              />
              Liste des factures générées
            </b-dropdown-item>

            <b-dropdown-item @click="cancelInvoice(data.item)">
              <feather-icon
                icon="TrashIcon"
                size="18"
                style="margin-right: 5px"
              />
              Placer dans la corbeille
            </b-dropdown-item>
          </b-dropdown>
        </template>
        <template #cell(date)="data">
          {{ getDate(data.item.created_at) }}
        </template>
        <template #cell(#)>
          <img src="../../../../public/pdf.svg" width="25" alt="" />
        </template>
      </b-table>

      <b-modal
        size="sm"
        hide-footer
        hide-header-close
        no-close-on-backdrop
        v-model="showSendingModal"
        id="modal-center"
        centered
        :title="'Envoi de Facture N° : ' + invoice.num"
      >
        <!-- <h4>
          Facture de : <b>{{ invoice.customer.name }}</b>
        </h4> -->
        <b-overlay :show="loading" rounded="sm">
          <p>Voulez-vous vraiment envoyer cette facture au client ?</p>
          <br />
          <div class="text-center">
            <b-button
              @click="(showSendingModal = false), (showModal = false)"
              class="mr-1"
              variant="outline-primary"
              size="sm"
              >Non, annuler</b-button
            >
            <b-button size="sm" @click="sendInvoice(invoice)"
              >Oui, envoyer</b-button
            >
          </div>
        </b-overlay>
      </b-modal>
      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <!-- page length -->
        <b-form-group
          label="Affichage"
          label-cols="6"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
        >
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            inline
            :options="pageOptions"
          />
        </b-form-group>

        <!-- pagination -->
        <div>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { BToast } from "bootstrap-vue";
import ToastificationContent from "@/@core/components/toastification/ToastificationContent.vue";
import { mapState, mapActions, mapGetters } from "vuex";
import moment from "moment";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
export default {
  components: {
    BToast,
    ToastificationContent,
    FeatherIcon,
  },
  setup() {
    return {
      isBusy: false,
      sendIsProgress: false,
      exportIsProgress: false,
      loading: false,
      isNumNull: false,
      text: "This will get copied!",
      showSendingModal: false,
      invoice: {
        num: "",
      },
      showModal: false,
      perPage: 10,
      pageOptions: [10, 15, 20],
      totalRows: 1,
      currentPage: 1,
      sortBy: "date",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],

      instanceCard: false,
      generatedCard: true,
      fields: [
        // { key: "id", label: "#", sortable: true },
        { key: "num", label: "Numéro", sortable: true },
        { key: "customer", label: "Client", sortable: true },
        { key: "periodicity", label: "Périodicité", sortable: true },
        { key: "is_sent", label: "" },
        { key: "date", label: "Date", sortable: true },
        { key: "totalTTC", label: "Total TTC(€)", sortable: true },
        { key: "actions", thStyle: { width: "5%" } },
      ],
    };
  },
  computed: {
    ...mapState("tasks", [
      "invoices",
      "allInvoiceAndDevisDeleted",
      "lastinvoice",
      "recurring_invoices",
    ]),
    ...mapGetters("tasks", ["varGetLastInvoice"]),

    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },

  mounted() {
    this.totalRows = this.recurring_invoices.length;
    this.isBusy = true;
    this.fetchRecurringInvoices().then(() => {
      this.isBusy = false;
    });
  },

  watch: {
    lastinvoice(val) {
      if (val)
        this.invoice.num =
          new Date().toISOString().split("T")[0] + "-" + val.id;
    },
  },

  methods: {
    ...mapActions("tasks", [
      "allRecurringInvoicesDeleted",
      "removeThisRecurringInvoice",
      "fetchRecurringInvoices",
      'exportRecurringInvoicesPdf'
    ]),

    downloadRecurringInvoicesCompressedFile() {
      this.exportIsProgress = true;
      this.exportRecurringInvoicesPdf()
        .then((response) => {
          this.exportIsProgress = false;

          window.location.href = response;
        })
        .catch((error) => {
          this.exportIsProgress = false;
        });
    },

    totalTTC(data) {
      var total = data.total;
      var tva = (total * data.tva) / 100;
      var tttc = total + tva;
      if (Number.isInteger(total) == true) {
        return tttc;
      } else {
        return tttc.toFixed(2);
      }
    },

    cancelInvoice(data) {
      this.removeThisRecurringInvoice(data.num).then((e) => {
        this.fetchRecurringInvoices();
        //this.invoicesAndInvoiceDeteled.push(data)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Facture placée dans la corbeille",
            icon: "BellIcon",
            variant: "success",
          },
        });
      });
    },

    resetForm() {
      this.showModal = false;
      this.invoice.num = null;
    },

    showInvoiceModal(data) {
      if (data.num != null) {
        this.isNumNull = true;
      } else {
        this.isNumNull = false;
      }
      this.showModal = true;
      this.invoice = data;
    },

    getDate(date) {
      return moment(date).locale("fr").format("DD MMMM YYYY");
    },

    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>

<style scoped>
.generated-card {
  max-height: 70vh;
}
</style>